var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('ModalDelay',{attrs:{"modal":_vm.showModal,"editModal":_vm.editModal,"item-delay":_vm.itemDelay,"titulo":_vm.titulo},on:{"update:modal":function($event){_vm.showModal=$event},"submited":_vm.handleSubmit}}),_c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"center-field",attrs:{"sm":"12","lg":"4"}},[_c('CSelect',{attrs:{"horizontal":{ label: 'col-sm-12 col-lg-auto text-right', input: 'col-sm-12 col-lg-7'},"label":_vm.$t('label.crane'),"options":_vm.craneOptions,"value":_vm.VisitCraneId},on:{"update:value":function($event){_vm.VisitCraneId=$event},"change":_vm.getDeviceDelay},model:{value:(_vm.VisitCraneId),callback:function ($$v) {_vm.VisitCraneId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"VisitCraneId"}})],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"8"}},[_c('h6',{staticClass:"text-warning mr-3 mt-2"},[_c('CIcon',{staticClass:"mb-2 mr-1",attrs:{"name":"alertas","color":"wipe","size":"lg"}}),_vm._v(_vm._s(_vm.$t('label.currentTime'))+"  ")],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.delay'),
                    placement: 'top-end'
                }),expression:"{\n                    content: $t('label.delay'),\n                    placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center",attrs:{"shape":"square","color":"add"},on:{"click":function($event){return _vm.newDelay()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('CRow',{staticClass:"mt-3"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedItems,"fields":_vm.fields,"items-per-page":5,"hover":"","sorter":"","small":"","pagination":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"details":""},scopedSlots:_vm._u([{key:"alertGif",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[(item.FgOpen)?_c('div',{staticClass:"animation-item ml-2",staticStyle:{"display":"inline-block"}},[_c('h3',{staticClass:"text-danger"},[_c('CIcon',{staticStyle:{"width":"1.2rem","height":"1.2rem","font-size":"1.5rem"},attrs:{"name":"alertas","color":"danger"}})],1)]):_vm._e()])]}},{key:"RowNumber",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.RowNumber)+" ")])]}},{key:"show_details",fn:function({item}){return [_c('td',{staticClass:"text-center"},[(item.MovsJson?.length !== 0 || item.DelaysJson?.length !== 0)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.toView')+' '+_vm.$t('label.details'),
                                placement: 'top-end',
                            }),expression:"{\n                                content: $t('label.toView')+' '+$t('label.details'),\n                                placement: 'top-end',\n                            }"}],staticClass:"mb-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.toggleLevel(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1):_vm._e()],1)]}},{key:"details",fn:function({item}){return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('div',{staticClass:"py-3 px-2"},[_c('dataTableExtended',{staticClass:"align-center-row-datatable table-lv-2",attrs:{"items":_vm.formatedItemsDetails,"fields":_vm.fieldDetails,"items-per-page":5,"sorter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText},scopedSlots:_vm._u([{key:"alertGifItem",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[(item.FgOpen)?_c('div',{staticClass:"animation-item ml-2",staticStyle:{"display":"inline-block"}},[_c('h3',{staticClass:"text-danger"},[_c('CIcon',{staticStyle:{"width":"1.2rem","height":"1.2rem","font-size":"1.5rem"},attrs:{"name":"alertas","color":"danger"}})],1)]):_vm._e()])]}},{key:"Id",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.Id)+" ")])]}},{key:"TransactionFinish",fn:function({ item }){return [(!item.FgOpen)?_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.TransactionFinish)+" ")]):_vm._e(),(item.FgOpen)?_c('td',{staticClass:"center-cell"}):_vm._e()]}},{key:"TotalTime",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.TotalTime)+" ")])]}},{key:"show_details",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                content: _vm.$t('label.edit')+' '+_vm.$t('label.delay'),
                                                placement: 'top-end',
                                            }),expression:"{\n                                                content: $t('label.edit')+' '+$t('label.delay'),\n                                                placement: 'top-end',\n                                            }"}],staticClass:"mb-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.editDelay(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}],null,true)})],1)])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }